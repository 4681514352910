<template>
  <div class="allSceneries">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>所有景区列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table :data="tableData" border>
      <el-table-column prop="cateId" label="景区ID"></el-table-column>
      <el-table-column prop="cateName" label="景区名称"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import { allScenery } from "../../api/sceneryList";
export default {
  name: "allSceneries",
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.getAllSceneries();
  },
  methods: {
    async getAllSceneries() {
      const { data } = await allScenery();
      console.log(data);
      this.tableData = data.data;
    },
  },
};
</script>
<style lang="less" scoped>
.allSceneries {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
  }
}
</style>